export type CreditTypeDto = {
  value: string;
  name: string;
  description: string;
  amount: number;
};
export const CreditTypes: CreditTypeDto[] = [
  {
    name: "Chatbot Message by User",
    value: "assistant-message",
    description: "Messages sent by the user to the chatbot",
    amount: 1,
  },
  {
    name: "Get Data View via API",
    value: "data-view-api",
    description: "Gets the last data from the data view",
    amount: 1,
  },
  {
    name: "Data Sync",
    value: "data-sync",
    description: "Syncs the chatbot data",
    amount: 1,
  },

  // {
  //   name: "Custom GPT Message",
  //   value: "gpt-message",
  //   description: "A message is sent by someone to the custom GPT",
  //   amount: 0,
  // },
] as const;

export type CreditType = (typeof CreditTypes)[number]["value"];
